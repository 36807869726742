import './Game.css'
import Board from "../Board/Board.js";
import { useEffect, useRef, useState } from 'react';
// import Button from "../Button/Button.js";


export default function Game(props) {

    const initialState = {
        count: 0,
        board: [
            { move: 0, color: '' },
            { move: 1, color: '' },
            { move: 2, color: '' },
            { move: 3, color: '' },
            { move: 4, color: '' },
            { move: 5, color: '' },
            { move: 6, color: '' },
            { move: 7, color: '' },
            { move: 8, color: '' },
        ],
        won: false,
        cpuChance: false,
    }

    const [gameState, setGameState] = useState(initialState);
    const isInitialMount = useRef(true);

    let turn = (gameState.turn === undefined) ? props.turn : gameState.turn;
    let cpuChance = gameState.cpuChance;
    let count = gameState.count;

    useEffect(() => {
        if (isInitialMount.current) {
            return;
        }
        setInitialGame();
        props.setReset(false);
    }, [props.reset])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else if (props.mode) {
            cpuChance = !cpuChance;
            if (cpuChance) {
                setTimeout(() => cpuTurn(movesBoard(gameState.board), props.mode), (300 + Math.random() * 1200));
            }
        }
    }, [gameState.board]);



    let cpuTurn = (board, mode) => {
        let i = 0;
        const unfilledBoard = emptySquares(board);

        switch (mode) {
            case 2: i = mediumMove(board, unfilledBoard, turn); break;
            case 3: i = hardMove(board, unfilledBoard, turn); break;
            case 4: i = impossibleMove(board, turn); break;
            default: i = easyMove(unfilledBoard);
        }
        updateGame(i);

    }
    let easyMove = (unfilledBoard) => {
        let randomSquare = Math.floor(Math.random() * unfilledBoard.length);
        let i = unfilledBoard[randomSquare];
        return i;
    }

    let mediumMove = (currentBoard, unfilledBoard, turn) => {

        // Check if CPU can win
        if (count < 3) {
            return easyMove(unfilledBoard);
        }
        let move = getMove(turn);
        for (let i of unfilledBoard) {
            currentBoard[i] = move;
            let result = evaluateResult(currentBoard).result;
            if (result) return i;
            currentBoard[i] = i;
        }

        // Check if CPU can prevent player from winning
        move = getMove(!turn);
        for (let i of unfilledBoard) {
            currentBoard[i] = move;
            let result = evaluateResult(currentBoard).result;
            if (result) return i;
            currentBoard[i] = i;
        }
        return easyMove(unfilledBoard);
    }

    let hardMove = (currentBoard, unfilledBoard, turn) => {
        if (count === 1) {
            let temp = [0, 2, 6, 8];
            if (!isFinite(currentBoard[4])) {
                let i = temp[Math.floor(Math.random() * 4)];
                return i;
            }
            for (let i of temp) {
                if (!isFinite(currentBoard[i])) return 4;
            }
        }

        if (count === 3) {
            if (currentBoard[4] === getMove(turn) && (currentBoard[0] === currentBoard[8] || currentBoard[2] === currentBoard[6])) {
                let temp = [1, 3, 5, 7];
                return temp[Math.floor(Math.random() * 4)];
            }
        }

        return mediumMove(currentBoard, unfilledBoard, turn);
    }

    let impossibleMove = (currentBoard, turn) => {

        const huPlayer = getMove(!turn);
        const aiPlayer = getMove(turn);

        function minimax(reboard, player) {
            let array = emptySquares(reboard);
            let result = evaluateResult(reboard).result;
            if (result) {
                if (result === huPlayer) {
                    return {
                        score: -10,
                    };
                } else {
                    return {
                        score: 10,
                    };
                }
            } else if (array.length === 0) {
                return {
                    score: 0,
                };
            }

            let moves = [];
            for (let i = 0; i < array.length; i++) {
                let move = {};
                move.index = reboard[array[i]];
                reboard[array[i]] = player;

                if (player === aiPlayer) {
                    let g = minimax(reboard, huPlayer);
                    move.score = g.score;
                } else {
                    let g = minimax(reboard, aiPlayer);
                    move.score = g.score;
                }
                reboard[array[i]] = move.index;
                moves.push(move);
            }

            let bestMove;
            if (player === aiPlayer) {
                let bestScore = -10000;
                for (let i = 0; i < moves.length; i++) {
                    if (moves[i].score > bestScore) {
                        bestScore = moves[i].score;
                        bestMove = i;
                    }
                }
            } else {
                let bestScore = 10000;
                for (let i = 0; i < moves.length; i++) {
                    if (moves[i].score < bestScore) {
                        bestScore = moves[i].score;
                        bestMove = i;
                    }
                }
            }
            return moves[bestMove];
        }

        return minimax(currentBoard, aiPlayer).index;
    }

    let playerTurn = (i) => {
        if ((!props.mode || !cpuChance) && isFinite(gameState.board[i].move)) updateGame(i);
    }

    function getMove(turn) {
        return (turn) ? "X" : "O";
    }

    function updateGame(i) {
        if (!gameState.won) {

            let result = false;
            let move = getMove(turn);
            let tempBoard = gameState.board.slice();
            tempBoard[i] = { color: turn ? "text-red-600" : "text-blue-600", move: move };
            count++;
            turn = !turn;
            if (count > 4) {

                let moveBoard = movesBoard(tempBoard);
                let resultObj = evaluateResult(moveBoard);

                result = resultObj.result;
                if (resultObj.result || !(emptySquares(moveBoard).length)) {
                    let gameStateObj = ({ count, board: tempBoard, won: result, cpuChance, turn: turn });
                    handleResult(resultObj, gameStateObj);
                    return;
                };
            }
            setGameState({ count, board: tempBoard, won: result, cpuChance, turn: turn });
        }
    }

    function emptySquares(board) {
        return board.filter((ele) => isFinite(ele));
    }

    function evaluateResult(board) {

        let winPlayer = { line: null, type: null, result: false };


        if (board[0] === board[1] && board[1] === board[2])
            winPlayer = { line: 0, type: "r", result: board[0] };

        else if (board[3] === board[4] && board[4] === board[5])
            winPlayer = { line: 1, type: "r", result: board[3] };

        else if (board[6] === board[7] && board[7] === board[8])
            winPlayer = { line: 2, type: "r", result: board[6] };


        else if (board[0] === board[3] && board[3] === board[6])
            winPlayer = { line: 0, type: "c", result: board[0] };

        else if (board[1] === board[4] && board[4] === board[7])
            winPlayer = { line: 1, type: "c", result: board[1] };

        else if (board[2] === board[5] && board[5] === board[8])
            winPlayer = { line: 2, type: "c", result: board[2] };


        else if (board[0] === board[4] && board[4] === board[8])
            winPlayer = { line: 0, type: "d", result: board[0] };

        else if (board[2] === board[4] && board[4] === board[6])
            winPlayer = { line: 1, type: "d", result: board[2] };

        return winPlayer;
    }

    function setInitialGame() {
        setGameState(initialState);
        isInitialMount.current = true;
    }

    function movesBoard(board) {
        return (board.map(ele => ele.move));
    }

    function colorSquares(resultObj, gameStateObj) {


        // Warning: Creation of refrence of gameStateObj board array which can be directly edited by tempBOard from now
        let tempBoard = gameStateObj.board;

        for (let i = 0; i < 9; i++) {
            tempBoard[i].color = "text-gray-700";
        }
        if (resultObj.result) {

            let type = resultObj.type;
            let i = resultObj.line;

            let winColor = "text-green-600";

            let winnerObj = {
                r: {
                    0: [0, 1, 2],
                    1: [3, 4, 5],
                    2: [6, 7, 8]
                },
                c: {
                    0: [0, 3, 6],
                    1: [1, 4, 7],
                    2: [2, 5, 8]
                },
                d: {
                    0: [0, 4, 8],
                    1: [2, 4, 6]
                }
            }

            //coloring green color to the winning one.
            for (let index of winnerObj[type][i]) {
                tempBoard[index].color = winColor;
            }
        }
        else {

        }
        setGameState(gameStateObj);
    }

    function handleResult(resultObj, gameStateObj) {
        colorSquares(resultObj, gameStateObj);
        props.winner(resultObj.result);

    }

    return (
        <Board board={gameState.board} onClick={(i) => playerTurn(i)} />
    )
}