import './App.css';
import Game from './components/Game/Game.js'
import Overlay from './components/Overlay/Overlay.js';
import Button from './components/Button/Button.js';
import { useState, useRef } from 'react';

//setTurn: turn = true/false;
function App() {
  
  const [reset, setReset] = useState(false);
  const gameStats = useRef({ turn: true, mode: 0 });

  const button = {
    pvp: <Button onClick={() => setMode(0)} key="pvp">PvP</Button>,
    pvcpu: <Button onClick={showModes} key="pvcpu">P vs CPU</Button>,
    replay: <Button onClick={replay} key="replay">Replay?</Button>,
    home: <Button onClick={() => { setReset(true); showOverlay(home()) }} key="home">Home</Button>,

    easy: <Button onClick={() => setMode(1)} key="easy">Easy</Button>,
    medium: <Button onClick={() => setMode(2)} key="medium">Medium</Button>,
    hard: <Button onClick={() => setMode(3)} key="hard">Hard</Button>,
    impossible: <Button onClick={() => setMode(4)} key="impossible">Impossible</Button>,
  }

  function showModes() {
    let obj = {
      heading: 'Difficulty',
      buttons: [button.easy, button.medium, button.hard, button.impossible],
      className: 'visible',
    }
    showOverlay(obj);
  }

  function setMode(mode) {
    gameStats.current.mode = mode;
    chooseTurn();
  }

  function chooseTurn() {
    let obj = {
      heading: "Select",
      buttons: (<div className='text-[30vmin]'>

        <span className='text-rose-400
         hover:scale-105 duration-150 inline-block' onClick={() => setTurn(true)}>X </span>
        /
        <span className='text-orange-400
         hover:scale-105 duration-150 inline-block' onClick={() => setTurn(false)}> O </span>

      </div>),
      className: 'visible'
    }
    showOverlay(obj);
  }

  function setTurn(turn) {
    gameStats.current.turn = turn;
    hideOverlay();
  }

  function hideOverlay() {
    setOverlay({
      heading: '',
      buttons: null,
      className: '',
    })
  }

  function home() {
    return {
      heading: "Tic Tac Toe",
      buttons: [button.pvp, button.pvcpu],
      className: "visible",
    }
  }

  function replay() {
    setReset(true);
    hideOverlay();
  }

  
  

  function showResult(result) {

    let heading = result ? `${result} Won!` : "It's a tie!";
    let colorClass = result ? "win" : "tie";
    let obj = {
      heading: heading,
      buttons: [button.replay, button.home],
      className: `visible ${colorClass}`,
    }
    showOverlay(obj);
  }

  function showOverlay(obj) {
    setOverlay(obj)
  }


  const [overlay, setOverlay] = useState(() => home());

  return (

    <>
      <div>

        <Overlay id="welcome" className={overlay.className}>
          <h1 className='mb-4'>{overlay.heading}</h1>
          {overlay.buttons}
        </Overlay>

      </div>

      {/* mode: 0=pvp,  1=easy,   2=medium,   3=hard,   4=impossible
       turn: true=X,   false:O
     */}
      <Game winner={(result) => showResult(result)} turn={gameStats.current.turn} mode={gameStats.current.mode} reset={reset} setReset={setReset}></Game>
    </>
  );
}

export default App;
