import Square from '../Square/Square.js'
import './Board.css';
// import { useEffect } from 'react';



export default function Board(props) {



  let board = props.board;
  function generateSquares() {
    let Squares = [];
    for (let i = 0; i < 9; i++) {
      let classes = " border-gray-700 ";
      let row = Math.floor(i / 3);
      let col = i % 3;
      if (col === 0 || col === 1) classes += "border-r-2 ";
      if (col === 1 || col === 2) classes += "border-l-2 ";
      if (row === 0) classes += "border-b-2 ";
      if (row === 1) classes += "border-t-2 border-b-2 ";
      if (row === 2) classes += "border-t-2 ";
      Squares.push(<Square className={classes} id={`${i}`} onClick={() => props.onClick(i)} key={i}
        move={ isFinite(board[i].move) ? null : board[i].move} color={board[i].color} />);
    }
    return Squares;
  }

  return (
    <div id="board" className='flex flex-wrap'>
      {generateSquares()}
    </div>
  );
}